import 'what-input';
import './js/utilities/PublicPath';
import './js/utilities/BrowserDetection';
import './js/utilities/SvgSprite';
import './js/libs/StickyFill';
import "core-js";

import { ResolutionHandler } from './js/utilities/ResolutionHandler';

import ComponentDispatcher from './js/utilities/ComponentDispatcher';
import ModalService from './js/services/ModalService';

import './scss/main.scss';

// sta qui perchè altrimenti i css delle dipenzenze vengono importati prima e risulta difficile sovrascriverli

const compDispatcher = new ComponentDispatcher();
const modalService = new ModalService();

ResolutionHandler();

// TODO Error: Loading chunk service-loader failed.
// import(/* webpackChunkName: `service-loader` */ './js/services/LoaderService.js').then((res) => {
//   const LoaderService = res.default;
//   window._loader_service = new LoaderService();
// });

compDispatcher.createAsyncComponents().then((res) => {
  compDispatcher.observeDomChanges();
});

import CustomMessages from './js/constants/CustomMessages';
import EventBus from 'gia/eventbus';
import { loggedInPromise } from './js/components/LoginComponent';

// PITTI20-365
export {
  CustomMessages,
  EventBus,
  loggedInPromise
};
