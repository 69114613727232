import dayjs from 'dayjs';
import Bouncer from 'formbouncerjs';


const BASE_CONF = {
  // Classes & IDs
  fieldClass: 'error', // Applied to fields with errors
  errorClass: 'error-message', // Applied to the error message for invalid fields
  fieldPrefix: 'bouncer-field_', // If a field doesn't have a name or ID, one is generated with this prefix
  errorPrefix: 'bouncer-error_', // Prefix used for error message IDs

  customValidations: {
    ccAboutToExpire(field) {
      if (field.name !== 'expirationDate' && field.id !== 'expirationDate' && field.value) return false;
      const today = dayjs();
      const [month, year] = field.value.split('/');
      if (month && year && year.length === 2 && month.length === 2) {
        const expirationDate = dayjs(new Date(`20${year}`, month, 0));
        const diff = expirationDate.diff(today, 'day');

        return diff > 0 && diff < 45;
      }
    },
    ccExpired(field) {
      if (field.name !== 'expirationDate' && field.id !== 'expirationDate' && field.value) return false;
      const today = dayjs();
      const [month, year] = field.value.split('/');
      if (month && year && year.length === 2 && month.length === 2) {
        const expirationDate = dayjs(new Date(`20${year}`, month, 0));
        const isAfter = today.isAfter(expirationDate);

        return isAfter;
      }
    }
  },

  // Patterns
  // Validation patterns for specific input types
  patterns: {
    email: /^([^\x00-\x20"(),.\x3a-\x3c>@\x5b-\x5d\x7f-\xff]+|"([^\x0d"\\\x80-\xff]|\\[\x00-\x7f])*")(\.([^\x00-\x20"(),.\x3a-\x3c>@\x5b-\x5d\x7f-\xff]+|"([^\x0d"\\\x80-\xff]|\\[\x00-\x7f])*"))*@([^\x00-\x20"(),.\x3a-\x3c>@\x5b-\x5d\x7f-\xff]+|\[([^\x0d\x5b-\x5d\x80-\xff]|\\[\x00-\x7f])*])(\.([^\x00-\x20"(),.\x3a-\x3c>@\x5b-\x5d\x7f-\xff]+|\[([^\x0d\x5b-\x5d\x80-\xff]|\\[\x00-\x7f])*]))*(\.\w{2,})+$/,
    url: /^(?:https?|HTTPS?|ftp|FTP):\/\/(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[01])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4])|(?:[\dA-Za-z\u00a1-\uffff]-*)*[\dA-Za-z\u00a1-\uffff]+(?:\.(?:[\dA-Za-z\u00a1-\uffff]-*)*[\dA-Za-z\u00a1-\uffff]+)*\.[A-Za-z\u00a1-\uffff]{2,}\.?)(?::\d{2,5})?(?:[#/?]\S*)?$/,
    number: /[+-]?\d*[,.]?\d+/,
    color: /^#?([\dA-Fa-f]{6}|[\dA-Fa-f]{3})$/,
    date: /(?:19|20)\d{2}-(?:(?:0[1-9]|1[0-2])-(?:0[1-9]|1\d|2\d)|(?:(?!02)(?:0[1-9]|1[0-2])-30)|(?:(?:0[13578]|1[02])-31))/,
    time: /(0\d|1\d|2[0-3])(:[0-5]\d)/,
    month: /(?:19|20)\d{2}-(?:0[1-9]|1[0-2])/
  },

  // Message Settings
  messageAfterField: true, // If true, displays error message below field. If false, displays it above.
  messageCustom: 'data-bouncer-message', // The data attribute to use for customer error messages
  messageTarget: 'data-bouncer-target', // The data attribute to pass in a custom selector for the field error location

  // Error messages by error type
  messages: {
    ...window.formsErrorsMessages,
    outOfRange: {
      over: 'Please select a value that is no more than {max}.',
      under: 'Please select a value that is no less than {min}.'
    },
    wrongLength: {
      over: 'Please shorten this text to no more than {maxLength} characters. You are currently using {length} characters.',
      under: 'Please lengthen this text to {minLength} characters or more. You are currently using {length} characters.'
    }
  },

  // Form Submission
  disableSubmit: true, // If true, native form submission is suppressed even when form validates

  // Custom Events
  emitEvents: true // If true, emits custom events
};

export const validateInput = () => {
  const bouncer = new Bouncer(null, BASE_CONF);
  return bouncer.validate;
};

export default class FormValidator {
  get BASE_CONF() {
    return BASE_CONF;
  }

  get VALIDATOR() {
    return this.validator;
  }

  get VALIDATE() {
    const validator = new Bouncer(null, this.BASE_CONF);
    return validator.validate;
  }

  constructor(selector) {
    this.selector = selector;
    this.cleanFledsError();
  }

  start() {
    this.validator = new Bouncer(this.selector || 'form:not([data-bouncer-novalidate])', this.BASE_CONF);
  }

  cleanFledsError() {
    document.addEventListener('bouncerRemoveError', function (event) {
      // The field with the error
      const parent = event.target.parentElement;
      const customError = parent.querySelector('.js-error-message');
      if (customError) {
        parent.removeChild(customError);
      }
    }, false);
    // Detect show error events
    document.addEventListener('bouncerShowError', function (event) {
      // The field with the error
      const parent = event.target.parentElement;
      const customError = parent.querySelector('.js-error-message');
      if (customError) {
        parent.removeChild(customError);
      }
    }, false);
  }
}
