import { detect } from 'detect-browser';

const browserInfo = detect();

/**
 *
 * Set the browser info on the html element
 * @param obj
 * @return {Promise<void>}
 */
async function setBrowserAttributes(obj) {
  const promise = new Promise((resolve, reject) => {
    try {
      Object.keys(obj)
        .forEach(key => {
          document.documentElement.setAttribute(`data-browser-${key}`, obj[key]);
        });
      resolve(obj);
    } catch (e) {
      reject(e);
    }
  });

  const res = await promise;
  console.debug(res);
}

if (browserInfo) setBrowserAttributes(browserInfo);
