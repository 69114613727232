import BaseComponent from '../abstracts/BaseComponent';

let component;

export default class LoginComponent extends BaseComponent {

  get SELECTORS() {
    return {
      loginModalForm: '#login-form'
    };
  }

  constructor(element) {
    super(element);
    component = this;

    this.GRAB(this.CUSTOM_MESSAGES.MODAL_EVENTS.afterOpen, event => {
      this.afterOpenRequestAccess(event.vexInstance.contentEl.querySelector('.modal-content--request-access'));
      this.afterOpenContact(event.vexInstance.contentEl.querySelector('.modal-content--contact-form'));
    });
  }

  afterOpenRequestAccess(element) {
    const form = element && element.querySelector('form');
    // console.log('form', form);

    if (!form) {
      return;
    }

    let handler;
    handler = event => {
      // console.log('handler');

      event.preventDefault();
      form.removeEventListener('submit', handler);

      this.submit(form).then(response => {
        // console.log('response', response);

        if (!response) {
          return;
        }
        this.EMIT(this.CUSTOM_MESSAGES.MODAL_EVENTS.close);
      });
    };

    form.addEventListener('submit', handler);
  }

  afterOpenContact(element) {
    const form = element && element.querySelector('form');
    // console.log('form', form);

    if (!form) {
      return;
    }

    let handler;
    handler = event => {
      // console.log('handler');

      event.preventDefault();
      form.removeEventListener('submit', handler);

      this.submit(form).then(response => {
        // console.log('response', response);

        if (!response) {
          return;
        }
        this.EMIT(this.CUSTOM_MESSAGES.MODAL_EVENTS.close);
      });
    };

    form.addEventListener('submit', handler);
  }

  showModalForm(resolve) {
    // console.log('showModalForm');

    this.GRAB(this.CUSTOM_MESSAGES.MODAL_EVENTS.afterOpen, event => {
      const form = event.vexInstance.contentEl.querySelector('.login-register-component form');
      // console.log('form', form);

      if (!form) {
        return;
      }

      let handler;
      handler = event => {
        // console.log('handler');

        event.preventDefault();
        form.removeEventListener('submit', handler);

        this.submit(form).then(response => {
          // console.log('response', response);

          if (!response.success) {
            return;
          }
          this.useToken(form.getAttribute('action'), response.token).then(response2 => {
            // console.log('response2', response2);
            if (loggedIn(response2)) {
              this.EMIT(this.CUSTOM_MESSAGES.MODAL_EVENTS.close);
              this.EMIT(this.CUSTOM_MESSAGES.USER_EVENTS.loggedIn);
              resolve(true);
            }
          });
        });
      };

      form.addEventListener('submit', handler);
    });

    this.EMIT(this.CUSTOM_MESSAGES.MODAL_EVENTS.show, {
      selector: this.SELECTORS.loginModalForm
    });
  }

  submit(form) {
    // console.log('submit form', form);
    return new Promise(resolve => {
      fetch(`${form.getAttribute('action')}?async=true`, {
        credentials: 'same-origin',
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams(new FormData(form)).toString()
      })
      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error("Not 200 response");
        }
      })
      .then(json => {
        resolve(json);
      });
    });
  }

  useToken(pathname, token) {
    return new Promise(resolve => {
      fetch(`${pathname}?usetoken=true&tokendaauthenticator=${encodeURIComponent(token)}&noredirect=true`, {
        credentials: 'same-origin',
        method: 'GET',
        headers: {
          Accept: 'application/json'
        }
      })
      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error("Not 200 response");
        }
      })
      .then(json => {
        resolve(json);
      });
    });
  }
}

export function loggedIn(value) {
  if (typeof value !== 'undefined') {
    window.pittiEco.loggedIn = value;
  }
  return window.pittiEco.loggedIn;
}

export function loggedInPromise() {
  // console.log('loggedInPromise');

  return new Promise(resolve => {

    if (loggedIn()) {
      resolve(true);
      return;
    }

    component.showModalForm(resolve);
  });
}