import 'vex-js/dist/css/vex.css';
import VexDialog from 'vex-dialog';
import Vex from 'vex-js';
import Bean from 'bean';

import BaseBaseService from '../abstracts/BaseService';

const debug = false;

export default class ModalService extends BaseBaseService {
  get Messages() {
    return {
      [this.CUSTOM_MESSAGES.MODAL_EVENTS.show]: this.showModal,
      [this.CUSTOM_MESSAGES.MODAL_EVENTS.close]: this.closeModals
    };
  }

  get SELECTORS() {
    return {
      updateIdElement: '.js-update-id'
    }
  }

  get DEFAULT_OPTS() {
    return {
      content: '',
      unsafeContent: '',
      showCloseButton: true,
      escapeButtonCloses: true,
      overlayClosesOnClick: true,
      appendLocation: 'body',
      className: 'vex-theme-default',
      overlayClassName: '',
      contentClassName: '',
      closeClassName: '',
      closeAllOnPopState: true
    };
  }

  constructor(element) {
    super(element);

    this.Vex = Vex;
    this.Vex.registerPlugin(VexDialog);

    this.handleResolutionChange();
  }

  // async importVex() {
  //   try {
  //     await import(/* webpackChunkName: "vex-css" */ 'vex-js/dist/css/vex.css');
  //     const VexDialog = await import(/* webpackChunkName: "vex-dialog" */ 'vex-dialog');
  //     this.Vex = await import(/* webpackChunkName: "vex-js" */ 'vex-js');
  //     this.Vex.registerPlugin(VexDialog.default);
  //   } catch (e) {
  //     console.error(e);
  //   }
  // }

  handleResolutionChange() {
    const startWidth = window.innerWidth;
    Bean.on(window, 'resize.modal', (e) => {
      if (window.innerWidth !== startWidth) {
        const instances = this.Vex.getAll();
        const ignoreClose = this.objectIsEmpty(instances) || Object.values(instances).some(instance => instance.doNotCloseModalsOnResize);
        debug && console.log('ModalService ignoreClose', ignoreClose);
        if (ignoreClose) {
          return;
        }
        this.closeModals();
      }
    });
  }

  async focus({ contentEl }) {
    const focusable = this.getFirstFocusable(contentEl);
    focusable.focus();
  }

  updateIds(modalContent) {
    const children = modalContent.querySelectorAll(this.SELECTORS.updateIdElement);
    [...children].forEach(child => {
      if (child.getAttribute('id')) {
        const currentId = child.getAttribute('id');
        const modalId = `${currentId}__layer`
        child.setAttribute('id', modalId);
        if (child.getAttribute('name')) {
          const currentName = child.getAttribute('name');
          const modalName = `${currentName}__layer`
          child.setAttribute('name', modalName);
        }
      } else if (child.getAttribute('for')) {
        const currentFor = child.getAttribute('for');
        const modalFor = `${currentFor}__layer`
        child.setAttribute('for', modalFor);
      } 
    });
  }


  showModal(opts = {}) {
    const ctx = this;
    if (opts.selector) {
      if (!document.querySelector(opts.selector)) return console.warn('No element found with selector => ', opts.selector);
      opts.unsafeContent = document.querySelector(opts.selector).innerHTML;
    }
    return this.Vex.open({
      ...this.DEFAULT_OPTS,
      ...{
        afterOpen() {
          const vexInstance = this;
          const { contentEl } = vexInstance;
          const updateIds = contentEl.querySelector(ctx.SELECTORS.updateIdElement);
          if (updateIds) ctx.updateIds(contentEl);
          ctx.EMIT(ctx.CUSTOM_MESSAGES.MODAL_EVENTS.afterOpen, { vexInstance });
        },
        afterClose() {
          const vexInstance = this;
          ctx.EMIT(ctx.CUSTOM_MESSAGES.MODAL_EVENTS.afterClose, { vexInstance });
        },
        beforeClose() {
          const vexInstance = this;
          ctx.EMIT(ctx.CUSTOM_MESSAGES.MODAL_EVENTS.beforeClose, { vexInstance });
          document.body.classList.remove('vex-open');
        }
      },
      ...opts,
      className: `vex-theme-default ${opts.className ? opts.className : ''}`
    });
  }

  closeModals() {
    return this.Vex.closeAll();
  }
}
