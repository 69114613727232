// import 'whatwg-fetch';

let svgSprite;
let svgSpriteElement;

async function getSvgSprite() {
  if (!svgSprite) {
    const response = await fetch(`${document.documentElement.dataset.publicpath}img/sprite/sprite.svg`,
      {
        mode: 'no-cors',
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      });
    svgSprite = await response.text();
  }
  if (!svgSpriteElement) {
    svgSpriteElement = document.createElement('div');
    svgSpriteElement.setAttribute('id', 'svg-icons');
    svgSpriteElement.style.display = 'none';
  }
  svgSpriteElement.innerHTML = svgSprite;
  if (document.body) {
    document.body.prepend(svgSpriteElement);
  } else {
    getSvgSprite();
  }
}

getSvgSprite();
