
import Bean from 'bean';
import Freeze from '../utilities/Freeze';
import CommonInterface from './CommonInterface';

export default class BaseComponent extends CommonInterface {
  get $el() {
    return this._componentElement;
    //return $(this._componentElement) per jquery
  }

  get $options() {
    const compOptions = {};
    const options = { ...this.$el.dataset };
    const optionsEntires = Object.keys(options).filter(entry => entry.includes('option'));
    optionsEntires.forEach((entry) => {
      try {
        const cleanEntry = BaseComponent.cleanOptionKey(entry);
        let optionValue;
        if (options[entry].includes('{') && options[entry].includes('{')) {
          optionValue = JSON.parse(options[entry].replace(/'/g, '"'));
        } else {
          optionValue = BaseComponent.convertType(options[entry]);
        }
        compOptions[cleanEntry] = optionValue;
      } catch (e) {
        console.error('get $options() from component => ', this._componentElement, e);
      }
    });
    return Freeze(compOptions);
  }

  get COMPONENT_NAME() {
    return this.$el.getAttribute('data-component');
  }

  /**
   *
   * constructor
   *
   * @param {Element} element
   */
  constructor(element) {
    super();
    this._componentElement = element;
    this._componentElement.setAttribute('data-component-init', true);
  }

  static convertType(option) {
    let optionReturn;
    if (Number(option)) return Number(option);
    switch (option) {
      case 'false':
        optionReturn = false;
        break;
      case 'true':
        optionReturn = true;
        break;
      case 'null':
        optionReturn = null;
        break;
      default:
        optionReturn = option;
        break;
    }

    return optionReturn;
  }

  static cleanOptionKey(key) {
    const str = key.replace('option', '');
    const cleanedKey = `${str.charAt(0).toLocaleLowerCase()}${str.slice(1)}`;
    return cleanedKey;
  }

  /**
   *
   * $on
   *
   * @param {String} events
   * @param {Function} cb
   * @param {Element} [element = this.$el]
   * @param {Array|String|Element} [elements = null]
   */
  $on(events, cb, element = this.$el, elements = null) {
    if (!element || !events || !cb) return;
    if (!elements) {
      Bean.on(element, events, (e) => {
        if (e) e.stopPropagation();
        cb(e);
      });
    } else {
      Bean.on(element, events, elements, (e) => {
        if (e) e.stopPropagation();
        cb(e);
      });
    }
  }

  /**
   *
   * $one
   *
   * @param {String} events
   * @param {Function} cb
   * @param {Element} [element = this.$el]
   */
  $one(events, cb, element = this.$el) {
    Bean.one(element, events, cb);
  }

  /**
   *
   * $off
   *
   * @param {String} events
   * @param {Element} [element = this.$el]
   */
  $off(events, element = this.$el) {
    Bean.off(element, events);
  }

  /**
   *
   * $fire
   *
   * @param {String} events
   * @param {Element} [element = this.$el]
   */
  $fire(events, element = this.$el) {
    Bean.fire(element, events);
  }

  /**
   *
   * @param {HTMLElement} [container]
   */
  loading(container, message) {
    this.EMIT(this.CUSTOM_MESSAGES.LOADER_EVENTS.show, {
      container: container || this.$el,
      message
    });
  }

  /**
   *
   * @param {HTMLElement} [container]
   * @param {String} [message]
   * @param {String} [icon]
   * @param {Boolean} [error=false]
   */
  endLoading(container, message, error = false, icon) {
    this.EMIT(this.CUSTOM_MESSAGES.LOADER_EVENTS.hide, {
      container: container || this.$el,
      message,
      icon,
      error
    });
  }

  async * events(element, name, condition) {
    let resolve;
    element.addEventListener(name, event => {
      if (condition(event)) {
        resolve(event);
      }
    });
    while (true) {
      yield await new Promise(_resolve => resolve = _resolve);
    }
  }
}
