import config from 'gia/config';
import EventBus from 'gia/eventbus';
import CustomMessages from '../constants/CustomMessages';
import { getFirstFocusable, getAllFocusableElements } from '../utilities/FocusableElementUtils';

export default class CommonInterface {
  get CUSTOM_MESSAGES() {
    return CustomMessages;
  }

  get Messages() {
    return {};
  }

  constructor() {
    config.set('log', false);
    this._grabMessages();
  }

  _grabMessages() {
    this._messages = {};
    for (const message in this.Messages) {
      if (this.Messages[message]) {
        const __ref__ = this.Messages[message].bind(this);
        this._messages[message] = __ref__;
        this.GRAB(message, __ref__);
      }
    }
  }

  _ungrabMessages() {
    for (const message in this._messages) {
      this.UNGRAB(message, this._messages[message]);
    }
  }

  objectIsEmpty(object) {
    for (var key in object) {
      if (object.hasOwnProperty(key)) return false;
    }
    return true;
  }

  /**
   *
   * EMIT
   *
   * @param {String} message
   * @param {Object} payload
   */
  EMIT(message, payload) {
    return EventBus.emit(message, payload);
  }

  /**
   *
   * GRAB
   *
   * @param {String} message
   * @param {Function} callBack
   */
  GRAB(message, callBack) {
    if (!callBack) return;
    // callBack.__Ref__ = callBack.bind(this);
    EventBus.on(message, callBack);
  }

  /**
   *
   * UNGRAB
   *
   * @param {String} message
   * @param {Function} callBack
   */
  UNGRAB(message, callBack) {
    if (!callBack) return; // callBack.__Ref__ = callBack.bind(this);
    EventBus.off(message, callBack);
  }

  getFirstFocusable(parent) {
    return getFirstFocusable(parent);
  }

  getFocusableElements(parent) {
    return getAllFocusableElements(parent);
  }

  _destroy() {
    this._ungrabMessages();
  }
}
